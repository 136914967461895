<template lang="pug">
  div(v-if="vipProfileComplete.profile")
    h4.h4-profile-edit {{ $t('vip.edit_profile') }}
    .rounded-circle.are-profile-photo(v-if="vipProfileComplete.profile.photo")
      img#image.img-profile-photo(src='https://cdn2.wuwow.tw/images/kidpic.png' v-if="vipProfileComplete.profile.photo === ''")
      img#image.img-profile-photo(:src='vipProfileComplete.profile.photo' v-else)
    span(v-else) {{ $t('please') }}{{ $t('upload') }}{{ $t('photo') }}
    form
      .are-profile-fileupload
        button.btn.btn-outline-primary.btn-file-photo
          | {{ $t('upload') }}{{ $t('photo') }}
        input#image_file_upload_input.ipt-file-photo(type='file' name='file' accept='image/jpeg,image/jpg,image/png' @change="getImage()")
      .are-profile-input
        .form-group.row
          label.col-md-2.col-form-label.label-profile(for='account')
            | {{ $t('account') }}
          .col-md-10
            input#account.form-control.ipt-profile(v-model='vipProfileComplete.profile.account' type='text' readonly='')
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('old_password') }}
          .col-md-10
            input.form-control.ipt-profile(v-model='passwordObject.oldPassword' :placeholder="$t('please') + $t('enter') + $t('old_password')" type='password')
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('new_password') }}
          .col-md-10
            input.form-control.ipt-profile(v-model='passwordObject.newPassword' :placeholder="$t('please') + $t('enter') + $t('new_password')" type='password')
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('confirm_password') }}
          .col-md-10
            input.form-control.ipt-profile(v-model='passwordObject.passwordConfirmation' :placeholder="$t('please') + $t('confirm') + $t('new_password')" type='password')
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('english_name') }}
          .col-md-10
            input.form-control.ipt-profile(v-model='vipProfileComplete.profile.english_name' type='text' readonly='')
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('birthday') }}
            span.text-danger *
          .col-md-10
            datepicker(v-model='vipProfileComplete.profile.birthday' :bootstrap-styling='true' format='yyyy-MM-dd' :placeholder="$t('birthday')" style='width: 100%')
        .form-group.row
          label.col-md-2.col-form-label.label-profile(for='gender')
            | {{ $t('gender') }}
            span.text-danger *
          .col-md-10
            select.custom-select.ddl-profile(v-model='vipProfileComplete.profile.gender')
              option(value='' disabled='')
                | {{ $t('please') }}{{ $t('select') }}{{ $t('gender') }}
              option(value='F') {{ $t('female') }}
              option(value='M') {{ $t('male') }}
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('learning_target') }}
            span.text-danger *
          .col-md-10
            select.custom-select.ddl-profile(v-model='vipProfileComplete.profile.learning_target')
              option(value='-1' disabled)
                | {{ $t('please') }}{{ $t('select') }}{{ $t('learning_target') }}
              option(value='work') {{ $t('work') }}
              option(value='personal') {{ $t('personal') }}
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('highest_education') }}
            span.text-danger *
          .col-md-10
            select.custom-select.ddl-profile(v-model='vipProfileComplete.education.education')
              option(value='' disabled='')
                | {{ $t('please') }}{{ $t('select') }}{{ $t('education') }}
              option(value='children') {{ $t('under_elementary') }}
              option(value='elementary_school') {{ $t('elementary_school') }}
              option(value='junior_high_school') {{ $t('junior_high_school') }}
              option(value='high_school') {{ $t('high_school') }}
              option(value='bachelor') {{ $t('bachelor') }}
              option(value='master') {{ $t('master') }}
              option(value='doctor') {{ $t('doctor') }}
        .form-group.row
          label.col-md-2.col-form-label.label-profile
            | {{ $t('email') }}
            span.text-danger *
          .col-md-10
            input.form-control.ipt-profile(v-model='vipProfileComplete.profile.email' :placeholder="$t('email')" type='text')
        .form-group.row
          label.col-md-2.col-form-label.label-profile(for='phone')
            | {{ $t('work_phone') }}
          .col-md-10
            input.form-control.ipt-profile(v-model='vipProfileComplete.profile.work_phone' name='phone' :placeholder="$t('numbers_only')" type='text')
        .form-group.row
          label.col-md-2.col-form-label.label-profile(for='home_phone')
            | {{ $t('home_phone') }}
          .col-md-10
            input.form-control.ipt-profile(v-model='vipProfileComplete.profile.home_phone' name='home_phone' :placeholder="$t('numbers_only')" type='text')
        .form-group.row
          label.col-md-2.col-form-label.label-profile(for='address')
            | {{ $t('address') }}
            span.text-danger *
          .col-md-10
            twzipcode.blk-profile-zipcode(ref='twzipcode' :class-names="{\
            county: 'form-control ddl-profile',\
            district: 'form-control ddl-profile',\
            zipcode: 'form-control ddl-profile',\
            }" :default-zipcode='vipProfileComplete.address.postal_code' @update='result')
              .blk-profile-address
            input#address.form-control.ipt-profile(v-model='vipProfileComplete.address.region' type='text')
        b-alert.text-center(variant='warning' show='') {{ $t('vip.confidentiality_of_personal_information_mes') }}
</template>

<script type="text/javascript">
import datepicker from 'vuejs-datepicker';
import Twzipcode from 'vue2-twzipcode';

export default {
  name: 'VipProfileEdit',

  components: {
    datepicker,
    Twzipcode,
  },

  props: {
    vipProfileComplete: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      passwordObject: {
        oldPassword: '',
        newPassword: '',
        passwordConfirmation: '',
      },
    };
  },

  methods: {
    result({ zipcode, county, district }) {
      this.vipProfileComplete.address.country = county;
      this.vipProfileComplete.address.city = district;
      this.vipProfileComplete.address.postal_code = zipcode;
    },

    getImage() {
      const input = document.getElementById('image_file_upload_input');
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (element) => {
          document.getElementById('image').src = element.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.vipProfileComplete.profile.photo = input.files[0];
      }
    },
  },
};
</script>
