export default {
  messages: {
    en: {
      'editSuccess': 'Edit successfully!',
      'newPasswordEditSuccess': 'New Password Changed, please login again',
      'editFailed': 'Edit failed!',
      'filed_empty': 'Required fields are blank',
      'old_pw_match_pw': 'The old password should not be the same as the new password',
      'pw_not_match': 'New password is not match password confirmation',
      'enterNewPassword': 'Please enter a new password',
    },
    tw: {
      'editSuccess': '編輯成功!',
      'newPasswordEditSuccess': '新密碼變更成功，請重新登入',
      'editFailed': '編輯失敗，請確認必填欄位與格式!',
      'filed_empty': '有必填欄位空白',
      'old_pw_match_pw': '舊密碼請勿與新密碼相同，請修改新密碼',
      'pw_not_match': '新密碼與密碼確認不相同',
      'enterNewPassword': '請輸入新密碼',
    },
  },
};
