<template lang="pug">
  .reg-profile-edit
    .wuwow-card
      .wuwow-card-body
        porfile-edit(ref='porfile-edit' :vipProfileComplete="vipProfileComplete")
      .wuwow-card-footer.text-center
        button.btn.btn-primary(@click='editPorfile()' :disabled="!filedsAllFilled")
          | {{ $t('save') }}

</template>

<script type="text/javascript">
import { alertMessage, alertHtmlMessage } from '@/utils/sweetAlert.js';
import i18n from './lang';
import porfileEdit from '@/components/vip/profile/profileEdit';
import lioshutanApi from '@/api';
import { logout } from '@/composable/auth';
import { isObjectEmpty, checkRequireFileds } from '@/utils/verify';

export default {
  name: 'VipEdit',

  components: {
    porfileEdit,
  },

  i18n,

  data() {
    return {
      vipProfileComplete: {},
    };
  },

  computed: {
    filedsAllFilled(){
      if (!isObjectEmpty(this.vipProfileComplete)){
        // 必填欄位: 生日 性別 學習目標 最高學歷 電子郵件 居住地址
        const requireFileds = {
          profile: ['birthday', 'gender', 'learning_target', 'email'],
          education: ['education'],
          address: ['city', 'country', 'region'],
        };
        return checkRequireFileds(this.vipProfileComplete, requireFileds);
      }
      return false;
    },
  },

  created() {
    this.getvipAccountCompleteProfile();
  },

  methods: {
    getvipAccountCompleteProfile() {
      lioshutanApi.vip.getCompleteProfile().then((result) => {
        this.vipProfileComplete = result.data.profile_base;
      });
    },

    async editPorfile(){
      const pwConfirmed = this.passwordChangeCheck();
      // const res = await this.allFiledCheck();
      if (pwConfirmed) {
        const params = {
          ...this.vipProfileComplete,
          ...this.$refs['porfile-edit'].passwordObject,
        };
        try {
          const response = await lioshutanApi.vip.updateProfile(params);
          if (response.data.data.update.status === true) {
            // 先判斷是否有填寫密碼 如果有就登出
            if (this.$refs['porfile-edit'].passwordObject.passwordConfirmation){
              await alertMessage(this.$t('success'), 'success', this.$t('newPasswordEditSuccess'));
              logout();
            } else {
              await alertMessage(this.$t('success'), 'success', this.$t('editSuccess'));
            }
          }
        } catch (error) {
          alertHtmlMessage(this.$t('failed'), 'error', '請確認必填欄位已填寫', 'OK');
        }
      }
    },

    async allFiledCheck(){
      alertMessage(this.$t('failed'), 'error', this.$t('filed_empty'));
    },

    passwordChangeCheck(){
      const passwordObject = this.$refs['porfile-edit'].passwordObject;
      if (passwordObject.oldPassword && passwordObject.newPassword === '') {
        alertMessage(this.$t('failed'), 'error', '請輸入新密碼');
        return false;
      } else if (passwordObject.oldPassword !== passwordObject.newPassword &&
        passwordObject.newPassword === passwordObject.passwordConfirmation ||
        passwordObject.oldPassword === '' && passwordObject.newPassword === '') {
        return true;
      } else if (passwordObject.oldPassword === passwordObject.newPassword) {
        alertMessage(this.$t('failed'), 'error', this.$t('old_pw_match_pw'));
        return false;
      } else if (passwordObject.newPassword !== passwordObject.passwordConfirmation) {
        alertMessage(this.$t('failed'), 'error', this.$t('pw_not_match'));
        return false;
      }
    },

  },
};
</script>

<style lang="scss">
@import '@/styles/vips/profile';
</style>
